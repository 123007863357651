import React from "react"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {size, spacing, color, ensembles, composers } from "../constants"

import Layout from "../components/layout"
import SEO from "../components/seo"


const CustomLink = styled.a`
  opacity: 1 !important;
  display: inline-block;
  margin: 0 2em 2em 0;
  padding: 1em;
  background-color: ${color.COLOR2};
  color: white;
  text-decoration: none;
  &:hover {
    background-color: ${color.COLOR2INTENSE};
    -webkit-transition: background-color 0.25s linear;
    -ms-transition: background-color 0.25s linear;
    transition: background-color 0.25s linear;
  }
`

const CustomLinkDisabled = styled.span`
  display: inline-block;
  margin: 0 2em 2em 0;
  padding: 1em;
  background-color: ${color.COLOR2};
  color: white;
  text-decoration: none;
  cursor: default;
`

const PreistragerPage = () => {
  const intl = useIntl()
  return (
    <Layout title={intl.formatMessage({ id: "preistrager" })}>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "preistrager" })}
      />
      
      <h1><FormattedMessage id="ensembles" /></h1>
      <h2>2024</h2>
      {
        ensembles.filter((o) => { return o.jahr == 2024 }).map((ensemble,i) => (
          ensemble.url == ""
          ?
          <CustomLinkDisabled key={"ensemble"+i}>
            {ensemble.name} 
          </CustomLinkDisabled>
          :
          <CustomLink key={"ensemble"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
            {ensemble.name} 
          </CustomLink>
        ))
      }
      <h2>2023</h2>
      {
        ensembles.filter((o) => { return o.jahr == 2023 }).map((ensemble,i) => (
          ensemble.url == ""
          ?
          <CustomLinkDisabled key={"ensemble"+i}>
            {ensemble.name} 
          </CustomLinkDisabled>
          :
          <CustomLink key={"ensemble"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
            {ensemble.name} 
          </CustomLink>
        ))
      }
      <h2>2022</h2>
      {
        ensembles.filter((o) => { return o.jahr == 2022 }).map((ensemble,i) => (
          ensemble.url == ""
          ?
          <CustomLinkDisabled key={"ensemble"+i}>
            {ensemble.name} 
          </CustomLinkDisabled>
          :
          <CustomLink key={"ensemble"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
            {ensemble.name} 
          </CustomLink>
        ))
      }
      <h2>2021</h2>
      {
        ensembles.filter((o) => { return o.jahr == 2021 }).map((ensemble,i) => (
          ensemble.url == ""
          ?
          <CustomLinkDisabled key={"ensemble"+i}>
            {ensemble.name} 
          </CustomLinkDisabled>
          :
          <CustomLink key={"ensemble"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
            {ensemble.name} 
          </CustomLink>
        ))
      }
      <h2>2020</h2>
      {
        ensembles.filter((o) => { return o.jahr == 2020 }).map((ensemble,i) => (
          ensemble.url == ""
          ?
          <CustomLinkDisabled key={"ensemble"+i}>
            {ensemble.name} 
          </CustomLinkDisabled>
          :
          <CustomLink key={"ensemble"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
            {ensemble.name} 
          </CustomLink>
        ))
      }
      <h2>2019</h2>
      {
        ensembles.filter((o) => { return o.jahr == 2019 }).map((ensemble,i) => (
          ensemble.url == ""
          ?
          <CustomLinkDisabled key={"ensemble"+i}>
            {ensemble.name} 
          </CustomLinkDisabled>
          :
          <CustomLink key={"ensemble"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
            {ensemble.name} 
          </CustomLink>
        ))
      }
      <h2>2018</h2>
      {
        ensembles.filter((o) => { return o.jahr == 2018 }).map((ensemble,i) => (
          ensemble.url == ""
          ?
          <CustomLinkDisabled key={"ensemble"+i}>
            {ensemble.name} 
          </CustomLinkDisabled>
          :
          <CustomLink key={"ensemble"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
            {ensemble.name} 
          </CustomLink>
        ))
      }

    <h1 css={css`margin-top: 2em;`}><FormattedMessage id="komponierende" /></h1>
    <h2>2024</h2>
    {
      composers.filter((o) => { return o.jahr == 2024 }).map((ensemble,i) => (
        ensemble.url == ""
        ?
        <CustomLinkDisabled key={"composer"+i}>
          {ensemble.name} 
        </CustomLinkDisabled>
        :
        <CustomLink key={"composer"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
          {ensemble.name} 
        </CustomLink>
      ))
    }

    <h2>2023</h2>
    {
      composers.filter((o) => { return o.jahr == 2023 }).map((ensemble,i) => (
        ensemble.url == ""
        ?
        <CustomLinkDisabled key={"composer"+i}>
          {ensemble.name} 
        </CustomLinkDisabled>
        :
        <CustomLink key={"composer"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
          {ensemble.name} 
        </CustomLink>
      ))
    }

    <h2>2022</h2>
    {
      composers.filter((o) => { return o.jahr == 2022 }).map((ensemble,i) => (
        ensemble.url == ""
        ?
        <CustomLinkDisabled key={"composer"+i}>
          {ensemble.name} 
        </CustomLinkDisabled>
        :
        <CustomLink key={"composer"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
          {ensemble.name} 
        </CustomLink>
      ))
    }

    <h2>2021</h2>
    {
      composers.filter((o) => { return o.jahr == 2021 }).map((ensemble,i) => (
        ensemble.url == ""
        ?
        <CustomLinkDisabled key={"composer"+i}>
          {ensemble.name} 
        </CustomLinkDisabled>
        :
        <CustomLink key={"composer"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
          {ensemble.name} 
        </CustomLink>
      ))
    }

    <h2>2020</h2>
    {
      composers.filter((o) => { return o.jahr == 2020 }).map((ensemble,i) => (
        ensemble.url == ""
        ?
        <CustomLinkDisabled key={"composer"+i}>
          {ensemble.name} 
        </CustomLinkDisabled>
        :
        <CustomLink key={"composer"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
          {ensemble.name} 
        </CustomLink>
      ))
    }
    <h2>2019</h2>
    {
      composers.filter((o) => { return o.jahr == 2019 }).map((ensemble,i) => (
        ensemble.url == ""
        ?
        <CustomLinkDisabled key={"composer"+i}>
          {ensemble.name} 
        </CustomLinkDisabled>
        :
        <CustomLink key={"composer"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
          {ensemble.name} 
        </CustomLink>
      ))
    }
    <h2>2018</h2>
    {
      composers.filter((o) => { return o.jahr == 2018 }).map((ensemble,i) => (
        ensemble.url == ""
        ?
        <CustomLinkDisabled key={"composer"+i}>
          {ensemble.name} 
        </CustomLinkDisabled>
        :
        <CustomLink key={"composer"+i} href={ensemble.url} target="_blank" rel="noopener noreferrer" >
          {ensemble.name} 
        </CustomLink>
      ))
    }

    <CustomLink href="/download/preistraeger-2024-1974.pdf" target="_blank" rel="noopener noreferrer" 
      css={css`
        width: 100%;
        text-align: center;
        padding: 1em;
        margin: 3em 0 0 0;`
    }>
      <FormattedMessage id="preistrager" /> 1974 - 2024
    </CustomLink>
    </Layout>
  )
}

export default PreistragerPage